import { validate, validateSync } from "class-validator";

export abstract class Validatable {

  public validateSync() {
    const errors = validateSync(this);
    if (errors.length > 0) {
      throw new Error(errors.toString());
    }

    return errors;
  }

  public async validate() {
    const errors = await validate(this);
    return errors;
  }

}
