import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Text from "apps/website/components/base/Text/Text";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import TrustPilotStars from "apps/website/components/misc/TrustPilotStars";
import Column from "apps/website/components/layout/Column/Column";
import {
  Offset,
  BreakpointOffset,
  Span,
} from "apps/website/components/layout/Column/Column.map";

export interface ICondensedReviewListItemProps {
  avatar: IImageProps;
  body: string;
  author: string;
  theme?: Theme;
  className?: string;
  offset?: Offset | BreakpointOffset;
  xlSpans?: Span;
  hideTrustPilotStars?: boolean;
}

const CondensedReviewListItem: FC<ICondensedReviewListItemProps> = ({ author, avatar, body, theme = "default", className, offset, hideTrustPilotStars = false, xlSpans }) => (
  <Column spans={{ xl: xlSpans }} tag="li" component={CondensedReviewListItem.name} className={className} offset={offset}>
    <div className={`flex break-inside-avoid-column h-full ${themeRootClassMap[theme]} ${theme !== "default" ? "p-4" : "pb-4"}`} data-theme={theme}>
      <div className="flex flex-1">
        <div className="flex-none mr-4 w-20 h-20 relative rounded-full overflow-hidden">
          <Image image={avatar} alt={author} data-testid="CondensedReviewListItemAvatar" cover/>
        </div>
        <div>
          <Text
            tag="h2"
            display="subtitle"
            size={legacySizeCollectionMap.titleSm}
            data-testid="CondensedReviewListItemReview"
          >&ldquo;{ body }&rdquo;</Text>
          <Spacer size="sm" />
          <div className="flex space-x-4">
            { !hideTrustPilotStars ? (
              <div className="w-[100px]">
                <TrustPilotStars rating={5}/>
              </div>
            ) : (<></>) }
            <Text size={legacySizeCollectionMap.bodySm} data-testid="CondensedReviewListItemAuthor">{ author }</Text>
          </div>
        </div>
      </div>
    </div>
  </Column>
);

export default CondensedReviewListItem;
