import { SizeCollection } from "../Text/Text.map";

interface TagSize {
  classes: string;
  font: SizeCollection;
}

export type AvailableTag = "div" | "li";

export const tagSizeMap: Record<string, TagSize> = {
  large: {
    classes: "px-4 py-2",
    font: "titleLg",
  },
  medium: {
    classes: "px-4 py-2",
    font: "titleMd",
  },
  "medium-secondary": {
    classes: "px-2 py-0.5 lg:py-1",
    font: "titleMd",
  },
  "cluster-default": {
    classes: "px-4 py-2",
    font: "titleSm",
  },
  default: {
    classes: "px-4 py-2",
    font: "titleXs",
  },
  small: {
    classes: "px-2 py-1",
    font: "body2Xs",
  },
};

export type Size = keyof typeof tagSizeMap;
