"use client";

import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
} from "react";

import { ITrustpilotInfo } from "../api/Trustpilot";
import {
  TRUSTPILOT_REVIEWS_KEY,
  TRUSTPILOT_SCORE_KEY,
  TRUSTPILOT_SCORE_STRING_KEY,
} from "../constants/variables";

export const trustpilotContext = createContext<TrustpilotContext>(undefined as unknown as TrustpilotContext);

export interface TrustpilotContext extends ITrustpilotInfo {
  interpolate(s: string): string;
}

interface Props {
  info: ITrustpilotInfo;
}

export const TrustpilotProvider: FC<PropsWithChildren<Props>> = ({
  info,
  children,
}) => {

  const getStringFromStars = (stars: 3 | 3.5 | 4 | 4.5 | 5) => {
    const mappedStars = {
      3: "Average",
      3.5: "Average",
      4: "Great",
      4.5: "Great",
      5: "Exellent",
    };

    return mappedStars[stars];
  };

  const interpolate = useCallback(
    (input: string) => input
      .replaceAll(TRUSTPILOT_REVIEWS_KEY, info.reviews.toString())
      .replaceAll(TRUSTPILOT_SCORE_KEY, info.score.toString())
      .replaceAll(TRUSTPILOT_SCORE_STRING_KEY, getStringFromStars(info.stars)),
    [ info ],
  );

  const value = useMemo(() => ({ interpolate, ...info }), [ interpolate, info ]);
  return (
    <trustpilotContext.Provider value={value}>
      { children }
    </trustpilotContext.Provider>
  );
};

// Used for Storybook
const MOCK_TRUSTPILOT_VALUES: ITrustpilotInfo = {
  reviews: 3333,
  score: 4.9,
  stars: 5,
};

export const MockTrustpilotProvider: FC<PropsWithChildren> = ({
  children,
}) => (
  <TrustpilotProvider info={MOCK_TRUSTPILOT_VALUES}>
    { children }
  </TrustpilotProvider>
);
