import { Validatable } from "./Validatable";

type Constructable<T> = { new(): T };

export abstract class AutoValidated extends Validatable {

  public static create<T extends AutoValidated>(this: Constructable<T>, skipValidation = false) {
    const that = new this();
    if (skipValidation) return that;
    that.validateSync();
    return that;
  }

  // Creates the instance of the configuration,
  // but does not validate inside a browser environment.
  public static createWithoutClientValidation<T extends AutoValidated>(this: Constructable<T>) {
    const that = new this();
    // @ts-ignore TS-ignored because window is not included in some apps' types
    if (typeof window !== "undefined") return that;
    return that;
  }

}
