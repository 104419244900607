import { FC, PropsWithChildren, ReactNode } from "react";

import Text from "../../base/Text/Text";
import Column from "../../layout/Column/Column";
import Container from "../../layout/Container/Container";
import Spacer from "../../layout/Spacer/Spacer";
import { Size } from "../../base/Text/Text.map";
import Icon from "../../base/Icon/Icon";
import { IconSvg } from "../../base/Icon/Icon.map";

import {
  TAlertSize,
  TAlertStyle,
  alertPromptSizeMap,
  alertPromptStyleMap,
} from "./AlertPrompt.map";

export interface IAlertPromptProps {
  title?: string;
  style: TAlertStyle;
  hideImage?: boolean;
  children?: ReactNode;
  textSize?: Size;
  size?: TAlertSize;
}

export const AlertPrompt: FC<PropsWithChildren<IAlertPromptProps>> = ({
  title,
  children,
  style,
  textSize = "md",
  hideImage = false,
  size = "default",
}) => (
  <>
    { style !== "hidden" && (
      <>
        <Container
          component={AlertPrompt.name}
          padding="large"
          className={`w-full py-4 border-4 ${alertPromptSizeMap[size]} ${alertPromptStyleMap[style].background} ${alertPromptStyleMap[style].border}`}
        >
          <Column align="center">
            <Column direction="row" align="center">
              <>
                { (!hideImage && alertPromptStyleMap[style].icon) && (
                  <Icon icon={alertPromptStyleMap[style].icon as IconSvg} size="small" className="mr-2"/>
                ) }
                { title && (
                  <>
                    <Text display="title" size={textSize}>
                      { title }
                    </Text>
                  </>
                ) }
              </>
            </Column>
            <Spacer size="sm" />
            { children }
          </Column>
        </Container><Spacer size="md" />
      </>
    ) }
  </>
);
