import Axios, { AxiosInstance } from "axios";

export interface IBaseAPIOptions {
  withCredentials?: boolean;
}

export abstract class BaseAPI {

  protected readonly axios: AxiosInstance;

  constructor(
    baseURL: string,
    accessToken?: string,
    options?: IBaseAPIOptions,
  ) {
    const {
      withCredentials = false,
    } = options ?? {};

    this.axios = Axios.create({
      baseURL,
      withCredentials,
    });
    if (accessToken) this.updateAccessToken(accessToken);

  }

  public updateAccessToken(accessToken: string | undefined) {
    if (!accessToken) delete this.axios.defaults.headers.common.Authorization;
    else this.axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

}
